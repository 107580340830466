<template>
    <div class="software-container">
        <!-- <Header :required="true" title="Build and Create Digital Assets"
            subtitle="Click on know more to download the softwares" style="height: auto !important;">
        </Header> -->
        <div class="header pl-3">
            <h1 class="general-text-title">Build and Create Digital Assets</h1>
            <p>Click on know more to download the softwares</p>
        </div>
        <div class="software-body mt-10">
            <v-row style="width: 100%;">
                <v-col lg="3" v-for="(soft, index) in softwares" :key="index" class="pl-5">
                    <div class="soft-div" :style="{ background: `${soft.background}` }">
                        <div class="soft-image">
                            <img :src="soft.image" />
                        </div>
                        <div class="soft-title mt-5">
                            {{ soft.title }}
                        </div>
                        <div class="soft-tags mt-3">
                            <div class="tags" v-for="(tag, i) in soft.tags" :key="i">
                                <div class="tag">{{ tag }}</div>
                            </div>
                        </div>
                        <a :href="soft.url" target="_blank" style="text-decoration: none;width:100%;">
                            <v-btn class="btn mt-3">Explore</v-btn>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import Header from '../components/Header.vue';
export default {
    name: "Software",
    components: { Header },
    data() {
        return {
            softwares: [
                {
                    title: "Maya Autodesk",
                    tags: ['3D applications', 'Animated films', 'TV series', 'Visual effects'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/maya.png?alt=media&token=6767f12e-4144-4f8d-9b3e-b385dcf488c6',
                    background: "#0A4A4A",
                    url: "https://www.autodesk.in/products/maya/overview?term=1-YEAR&tab=subscription",
                },
                {
                    title: "Blender",
                    tags: ['Interactive 3D applications', 'Art', 'Motion Graphics', 'Visual Effects'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/blender.png?alt=media&token=4f20de6e-bff5-4a59-89de-f911abcd2253',
                    background: "#265787",
                    url: "https://www.blender.org/download/",
                },
                {
                    title: "Autodesk 3Ds Max",
                    tags: ['3D animations', '3D Games', '3D Models', '3D Images'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/3dsmax.png?alt=media&token=6eee59bf-b0be-4463-b5d8-4a5d7fd466e3',
                    background: "#082425",
                    url: "https://www.autodesk.in/products/3ds-max/overview?term=1-YEAR&tab=subscription",
                },
                {
                    title: "ZBrush",
                    tags: ['3D/2.5 Modeling', 'Texturing', 'Painting', 'Pixo Technology'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/zbrush.png?alt=media&token=c9537396-90e1-41f9-87aa-87ebe867061e',
                    background: "#36386A",
                    url: "https://pixologic.com/get-zbrush/",
                },
                {
                    title: "Unity",
                    tags: ['3D Games', '2D Games', 'Cross-platform game engine'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/unity.png?alt=media&token=fc919768-b1af-4ce7-906f-5e4646168106',
                    background: "#273131",
                    url: "https://unity3d.com/get-unity/download",
                },
                {
                    title: "Unreal Engine",
                    tags: ['3D applications', 'Animated films', '3D Computer Graphics'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/unreal.png?alt=media&token=cd74bd3c-38ca-4a6b-84e8-756f97997068',
                    background: "#2B2F4D",
                    url: "https://www.unrealengine.com/en-US/download",
                },
                {
                    title: "Cinema 4D",
                    tags: ['3D Modeling', 'Animation', 'Simulation', 'Rendering'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/cinema4d.png?alt=media&token=f03fc3e3-6d2b-4430-bf87-db93107381a4',
                    background: "#1C256B",
                    url: "https://cinema-4d.en.uptodown.com/windows/download",
                },
                {
                    title: "Houdini",
                    tags: ['3D Animation', 'Rigging', 'VFX', 'Video Game'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/houdini.png?alt=media&token=df8b3c9b-0aeb-49a2-a0ff-f8412d6e1bf9',
                    background: "#063F4B",
                    url: "https://www.sidefx.com/buy/",
                },
                {
                    title: "Decentraland",
                    tags: ['NFT Creation', 'Simulation', 'Video Game '],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/decentraland_logo-modified%201.png?alt=media&token=3e71d3c2-3274-4827-83f3-d3a140b450e4',
                    background: "#B721A0",
                    url: "https://builder.decentraland.org/",
                },
                {
                    title: "Vox Editor",
                    tags: ['3D Modeling', 'Video Game', 'NFT'],
                    image: 'https://firebasestorage.googleapis.com/v0/b/signax-test-72aae.appspot.com/o/the-sandbox%201.svg?alt=media&token=901bedfc-6787-488c-b548-663b1ee98fbf',
                    background: "#727AC0",
                    url: "https://www.voxedit.io/#/en/",
                }
            ],
        }
    },
}
</script>
<style scoped>
.software-container {
    width: 100%;
    height: auto;
    /* background-image: url("../assets/market/market_bg.svg"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/hue.svg");
    padding: 20px 20px 50px 20px;
}

.software-body {
    width: 100%;
}

.soft-div {
    width: 100%;
    height: 320px;
    border-radius: 15px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.soft-image {
    width: 100%;
    height: 100px;
}

.soft-image>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.soft-title {
    width: 100%;
    text-align: left;
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.tags {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    margin-bottom: 10px;
}

.soft-tags {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.tag {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-size: 12px;
}

.btn {
    width: 100%;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%) !important;
    font-weight: 400;
    font-size: 16px;
    font-family: "Bebas Neue", cursive;
    cursor: pointer;
    color: white;
    letter-spacing: 0.9px !important;
    border-radius: 5px;
    box-shadow: none !important;
}
.header > h1 {
  margin: 0px;
  width: 65%;
  font-size: 30px !important;
  line-height: 50px;
  font-weight: 800 !important;
}
.header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 20px;
  font-weight: 400;
}
</style>